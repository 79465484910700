import Carousel from 'react-bootstrap/Carousel';
import ba1 from '../bathroom/ba1.jpg';
import ba2 from '../bathroom/ba2.jpg';
import ba3 from '../bathroom/ba3.jpg';
import ba4 from '../bathroom/ba4.jpg';
import ba5 from '../bathroom/ba5.jpg';
import ba6 from '../bathroom/ba6.jpg';
import ba7 from '../bathroom/ba7.jpg';
import ba8 from '../bathroom/ba8.jpg';
import ba10 from '../bathroom/ba10.jpg';
import ba11 from '../bathroom/ba11.jpg';
import ba12 from '../bathroom/ba12.jpg';
import ba13 from '../bathroom/ba13.jpg';
import ba14 from '../bathroom/ba14.jpg';
import ba15 from '../bathroom/ba15.jpg';
import ba16 from '../bathroom/ba16.jpg';
import ba17 from '../bathroom/ba17.jpg';
import ba22 from '../bathroom/ba22.jpg';
import ba19 from '../bathroom/ba19.jpg';
import ba20 from '../bathroom/ba20.jpg';
import ba21 from '../W51/W51Bath.jpg';
import ba23 from '../W51/W51Bath2.jpg';
import ba24 from '../W51/W51Bath3.jpg';
import ba25 from '../W51/W51Bath4.jpg';



function BathroomCarousel() {
    return (
        <Carousel fade>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba21}
                    alt="First slide"
                />
            </Carousel.Item>
           
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba24}
                    alt="First slide"
                />
            </Carousel.Item>
             <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba23}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba25}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba16}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba17}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba22}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba19}
                    alt="First slide"
                />
            </Carousel.Item>
             <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba11}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba12}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba13}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba14}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba15}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba10}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba2}
                    alt="Second slide"
                />

               
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba4}
                    alt="Third slide"
                />             
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba5}
                    alt="Third slide"
                />             
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba6}
                    alt="Third slide"
                />             
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba7}
                    alt="Third slide"
                />             
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={ba8}
                    alt="Third slide"
                />             
            </Carousel.Item>
        </Carousel>


    );
}

export default BathroomCarousel;